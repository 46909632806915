<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        Configuration Status
      </h3>
      <br />
      <dl class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex flex-wrap space-x-1"
            >
              <asom-icon :icon="'timelapse'" size="sm" />
              <span>Threshold Before Expiry Date</span>
            </dt>
            <dd
              class="text-3xl font-semibold text-gray-900 text-left grid grid-flow-col justify-items-stretch"
            >
              <p>{{ thresholdExpireInDays }} Days</p>
              <div class="justify-self-end" @click="editThresholdExpireClicked">
                <asom-icon :icon="'external'" size="md" class="text-blue-500" />
              </div>
            </dd>
          </div>
        </aside>
      </dl>
    </article>
    <asom-modal
      v-model="showThresholdExpire"
      title="Edit Threshold Before Expiry Date"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="thresholdExpireError"
        :error-message="thresholdExpireError"
      />
      <asom-form-field
        required
        label="Days"
        :state="inputStates('formData.threshold')"
        error="Threshold Before Expiry Date is required"
      >
        <asom-input-text
          type="number"
          min="0"
          v-model.number="formData.threshold"
          :state="inputStates('formData.threshold')"
      /></asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="thresholdExpireEdit"
          :disabled="isSubmittingThresholdExpireEdit"
          :loading="isSubmittingThresholdExpireEdit"
          text="Confirm"
        />
        <asom-button
          @click="toggleThresholdExpireModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  getOpsCommConfigurationDetails,
  opsCommUpdateThresholdExpiration,
} from "../../../services/opsComms.service";
import { mapGetters } from 'vuex';

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      thresholdExpireInDays: null,
      showThresholdExpire: false,
      thresholdExpireError: null,
      isSubmittingThresholdExpireEdit: false,
      formData: {
        threshold: null,
      }
    };
  },
  validations() {
    return {
      formData: {
        threshold: { required }
      },
    };
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    ...mapGetters({
      lineId: 'auth/userLineId',
    }),
  },
  methods: {
    async getPageData() {
      const resp = await getOpsCommConfigurationDetails(this.lineId);
      if (resp.success) {
        this.thresholdExpireInDays = get(resp.payload, "thresholExpiryDays", 0);
        this.error = null;
      } else {
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    editThresholdExpireClicked() {
      this.toggleThresholdExpireModal(true);
    },
    toggleThresholdExpireModal(value) {
      this.thresholdExpireError = null;
      this.showThresholdExpire = value;
      this.formData.threshold = this.thresholdExpireInDays;
    },
    async thresholdExpireEdit() {
      this.thresholdExpireError = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.formData.$invalid) {
        this.isSubmittingThresholdExpireEdit = true;
        const result = await opsCommUpdateThresholdExpiration({
          expiredThresholdInDays: this.formData.threshold,
          lineId: this.lineId
        });
        if (result.success) {
          this.thresholdExpireInDays = this.formData.threshold;
          this.isSubmittingThresholdExpireEdit = false;
          this.toggleThresholdExpireModal(false);
          this.getPageData();
        } else {
          this.isSubmittingThresholdExpireEdit = false;
          this.thresholdExpireError = result.payload;
          this.$scrollTop();
        }
      } else {
        this.thresholdExpireError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
  },
};
</script>
